.app {
  font-family: "Source Sans Pro",sans-serif;
  font-size: 18px;
  line-height: 150%;
  height: 100%;
  text-rendering: optimizeLegibility;
  color: #5B6770;
}

.app .waiting{
  display: flex;
  justify-content: center;
  margin-top: 100px;
  margin-bottom: 100px;
}

.app .waiting .waiting-icon{
  color: #05A9B1;
}

.app .ir-data{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
}

.app .ir-data .ir-table-container{
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
}

.app .ir-data .ir-table-container .ir-title{
  font-weight: 700;
  color: #050139;
  padding: 0px;
  text-align: left;
  box-sizing: border-box;
  display: flex;
  align-items: baseline;
}

/* .app .ir-data .ir-table-container .ir-title h1{
  font-size: 35px;
}
.app .ir-data .ir-table-container .ir-title h2{
  font-size: 18px;
} */

.app .ir-data .ir-table-container .ir-table-box{
  width: 100%;
  padding: 0px 0px 25px 0px;
  box-sizing: border-box;
}

.app .ir-data .ir-table-container table{
  width: 100%;
  height: 100%;
  border-collapse: collapse;
}

.app .ir-data .ir-table-container table th{
  text-align: left;
  vertical-align: bottom;
}

.app .ir-data .ir-table .date-field,
.app .ir-data .ir-table .text-field,
.app .ir-data .ir-table .dollar-field,
.app .ir-data .ir-table .percent-field{
  text-align: right;
}

.app .ir-data .ir-table .ir-header{
  font-weight: 700;
  color:#050139;
  text-align: right;
}

.app .ir-data .ir-table .ir-column-header{
  font-weight: 700;
  color: #050139;
  text-align: left;
}

.app .ir-data .ir-table td{
  vertical-align: center;
  height: 45px;
}

.app .ir-data .ir-table .border-row{
  border-top: solid 1px #dcdcdc;
  padding: 0px;
}

.app .ir-data .ir-table-footnotes{
  font-size: 12px;
  line-height: 1.1em;
  padding: 0px 0px 25px 0px;

}

.app .ir-data .ir-table-footnotes p{
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
}

@media (max-width: 768px) {

  .app {
    font-size: 14px;
  }
  .app .ir-data .ir-table-container{
    row-gap: 15px;
  }
  /* .app .ir-data .ir-table-container .ir-title h1{
    font-size: 24px;
  }
  .app .ir-data .ir-table-container .ir-title h2{
    font-size: 14px;
  } */

  .app .ir-data{
    padding-right: 0px;
  }
  .app .ir-data{
    height: 100%;
  }
}
